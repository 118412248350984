import get from 'lodash/get';

/***SURVEY HELPER METHODS***/
export const isShownPage = (nodisplays, page, jobtype_title, values_customer) => { //checks DB values of pages not to display and conditions
    console.log("isShownPage")
    //const page = this.state.pages[-1];
    //const jobtype_title = this.state.jobtype.title;
    let category;
    console.log(nodisplays);
    console.log(jobtype_title)
    
    if (nodisplays[page]){                                   //if a doNotDisplay exists for this page
        if(jobtype_title && nodisplays[page][jobtype_title.toLowerCase()]){
            category = jobtype_title.toLowerCase();
        }
        else if (nodisplays[page]["default"]){
            category = "default";
        }
        console.log("category: " + category)
        console.log(nodisplays[page][category])
        if (category){
            for (const [condition, condition_value] of Object.entries(nodisplays[page][category])){  //check ONE condition at a time
                
                const state_val = get(values_customer, condition); //lodash/get to access state variable dynamically
                
                switch (condition) {
                    case "show":
                        if (condition_value === "never"){
                            return false;
                        } else if (condition_value === "always"){
                            return true;
                        }
                        break;
                }
                if (state_val === condition_value ){                          //if ANY conditions are not met, do not send to in person quotepage
                    return false;
                }
            }
            return true;
        }
    }
    else{
        return true;
    }
}

// selected value is the value of the selected choice, selected name is the name of the state variable that will be updated, 
// user_state_choices is the current state of the user's choices, inpersons is the inperson object from the DB, 
// referring_page is the page that called this method
export const sendToInpersons = (selected_value, selected_name, user_state_choices, inpersons, referring_page) => {
    console.log("sendToInpersons")
    console.log(selected_value)
    console.log(selected_name)
    //console.log(user_state_choices)
    console.log(inpersons)
    console.log(referring_page)
    try{
        let areatype = (user_state_choices.areatype) ? user_state_choices.areatype.title.toLowerCase() : null;
        if (selected_name === "jobtype"){ //in case state has not updated yet, because the value is currently being selected
            areatype = selected_value.title.toLowerCase();
        }
        let jobtype = (user_state_choices.jobtype) ? user_state_choices.jobtype.title.toLowerCase() : null;
        if (selected_name === "jobtype"){
            jobtype = selected_value.title.toLowerCase();
        }
        let servicetype = (user_state_choices.servicetype) ? user_state_choices.servicetype.service.toLowerCase() : null;
        if (selected_name === "servicetype"){
            servicetype = selected_value.service.toLowerCase();
        }
        const page = referring_page;
        let is_inperson = true;
        let job; //ultimitely set to either the servicetype, areatype, or default whatever is specifically identified in the Inpersons object
        
        // console.log("Inpersons")
        // console.log(selected_value)
        // console.log(selected_name)
        // console.log(user_state_choices)
        // console.log(inpersons)
        // console.log(referring_page)
        //console.log(selected_floorcondition)
        
        if (inpersons && inpersons[page]){  //if an inperson object exists for this page
            console.log("inpersons page", inpersons[page])
            if (inpersons[page][servicetype]) {                    //and exists for the current selected servictype (or servicetype or default)
                job = servicetype;
            }
            else if (inpersons[page][jobtype]) {
                job = jobtype;
            }
            else if (inpersons[page][areatype]) {
                job = areatype;
            }
            else if (inpersons[page]["default"]){
                job = "default";
            }
            console.log("job: " + job)
            console.log(inpersons[page][job])
            if (job){
                for (const [condition, condition_value] of Object.entries(inpersons[page][job])){  //check ONE condition at a time
                    console.log("condition: " + condition)
                    const state_val = selected_value || get(user_state_choices, condition); //lodash/get to access state variable dynamically
                    console.log("state_val: " + state_val)
                    console.log("condition_value: " + condition_value)
                    console.log("selected_name: " + selected_name)
                    
                    switch (condition) {
                        case "inperson":
                            if (condition_value === "never"){  //immediately DO NOT send to in person quote page, regardless of other conditions
                                return false;
                            } else if (condition_value === "always"){ //immediately DO send to in person quote page, regardless of other conditions
                                return true;
                            }
                            break;
                        case "minfloordamage":
                            if (!isNaN(selected_value)){
                                if (selected_value >= condition_value){
                                    return true;
                                } else {
                                    console.log("minfloordamage is NOT inperson")
                                    return false;
                                }
                            }
                            break;
                        case "maxfloordamage":
                            if (!isNaN(selected_value)){
                                if (selected_value <= condition_value){
                                    return true;
                                } else {
                                    console.log("maxfloordamage is NOT inperson")
                                    return false;
                                }
                            }
                            break;
                    }
                    if (Array.isArray(condition_value) && condition_value.length > 0){ //multiple values are possible for a single state_var, check all and if any are met, return true
                        console.log("condition_value is an array")
                        for (const value of condition_value){
                            console.log("value: " + value)
                            console.log("state_val: " + state_val)
                            if (state_val === value){
                                console.log ("state_val: " + state_val + " equals " + value + " so send to in person quotepage")
                                return true;
                            }
                        }
                        return false; //if none of the array values are met, do not send to in person quotepage
                    // } else if (state_val && (state_val !== condition_value) ){  //if ANY conditions are not met based on current state, do not send to in person quotepage
                    //     return false
                    // } else if (selected_name === condition && selected_value !== condition_value){ //if condition not met based on passed parameters, do not send to in person quotepage
                    //     return false
                    // }
                    } else
                        if (!conditionIsMet(condition, condition_value, user_state_choices)) {
                            console.log("condition not met:", condition, condition_value)
                            return false;
                        }
                    }
                } else {
                    console.log("no valid jobtype found, not sending to inpersons")
                    return false
                }
                console.log("all conditions met, send to in person quotepage")
                return true;
        }
        else{
            return false;
        }
    } catch (error) {
        console.log("error in sendToInpersons")
        console.log(error)
        throw error;
    }

}

export const findIndexByProperty = (arr, propertyName, propertyValue) => { //find index of object in array give a single property, else return -1
    if (!arr || arr.length === 0){
        return -1;
    }
    const index = arr.findIndex(obj => obj[propertyName] === propertyValue);
    console.log(propertyName + " with value " + propertyValue + " is at index " + index)
    return index;
}

export const findObjectByProperty = (arr, propertyName, propertyValue, case_sensitive = true) => { //find index of object in array give a single property
    if (!arr || arr.length === 0){
        return null;
    }
    // console.log("findObjectByProperty")
    console.log(arr)
    console.log(propertyName)
    console.log(propertyValue)
    // console.log(case_sensitive)
    let object = arr.find(obj => obj[propertyName] === propertyValue);
    if (!object && !case_sensitive){
        object = arr.find(obj => obj[propertyName].toLowerCase() === propertyValue.toLowerCase());
    }
    console.log("findObjectByProperty found: ", object)
    return object;
}

export const hasTwoUniqueValues = (arr, parameter) => {
    const uniqueValues = new Set();
    // console.log('hasTwoUniqueValues')
    // console.log(arr)
    // console.log(parameter)
    if (arr && arr.length > 1){
        for (const obj of arr) {
            const value = obj[parameter];
            uniqueValues.add(value);
            if (uniqueValues.size === 2) {
                return true;
            }
        }
    }
    return false;
}

// capitalize first letter of each word in a string
export const capatilizeFirstLetters = (string) => {
    return string.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
}

// check if a condition is met based on its key/value pairs
// as of 28 FEB 24, verified compatible with QuoteServices, AsphaltSurvey
export const conditionIsMet = (key, value, state_conditions) => { //key is the string of the state property to check, value is the value to check against
    let value_at_key = state_conditions[key];
    //check if key string ends in > or <, then check if value is greater or less than
    let is_greaterthan = false;
    let is_lessthan = false;
    let is_not = false;
    let is_notcontains = false;

    // if key inlcudes . , then parse and reference nested object in format this.state[key1][key2]...
    if(key.includes(".")){
        let keys = key.split(".");
        //initialize nested objexct to current state values
        if (keys){
            let nested_obj = state_conditions[keys[0]]; //initialize to state object
            for (let i = 1; i < keys.length; i++){
                console.log(nested_obj)
                console.log(keys[i]);
                nested_obj = nested_obj[keys[i]];
                console.log(nested_obj);
            }
            key = nested_obj;
            value_at_key = nested_obj;
            console.log(value_at_key);
        }
    }
    //check special characters
    if(key.includes(">")){
        is_greaterthan = true;
        value_at_key = state_conditions[key.substring(0, key.length-1)];
    } else if (key.includes("<")){
        is_lessthan = true;
        value_at_key = state_conditions[key.substring(0, key.length-1)];
    } else if (key.includes("!=")){
        is_not = true;
        value_at_key = state_conditions[key.substring(0, key.length-2)];
    } else if (key.includes("!contains")){
        is_notcontains = true;
        value_at_key = state_conditions[key.substring(0, key.length-9)];
    }

    console.log("checking condition: " + key + " is " + value + ", state value is " + value_at_key);
    console.log(value_at_key);
    if (is_greaterthan){
        if(value_at_key > value){ //if identical state property value does matches the excludeif condition
           //console.log(key.substring(0,key.length-1) + ' isgreaterthan' + value)
            return true;
        }
    } else if (is_lessthan){
        if(value_at_key < value){ //if identical state property value does matches the excludeif condition
           //console.log(key.substring(0,key.length-1) + ' islessthan' + value)
            return true;
        }
    }  else if (is_not){
        if(value_at_key !== value){ //if identical state property value does matches the condition
            console.log(typeof value)
            console.log( typeof value_at_key)
            return true;
        }
    } else if (is_notcontains){
        if(!value_at_key.includes(value)){ //if identical state property value does matches the condition
            return true;
        }
    }
    else{ 
        if(value_at_key === value){ //if identical state property value does matches the condition
            return true;
        }
    }
    return false;
}

export const setMobileStyling = (ismobile) => {
    console.log("setMobileStyling", ismobile)
    if(ismobile){ //if embedded or mobile, add mobile styles
        document.body.classList.add("mobile-styles");
        document.documentElement.style.fontSize = '12px';
        //document.documentElement.style.setProperty('max-width', '830px');
    } else {
        document.body.classList.remove("mobile-styles");
        document.documentElement.style.fontSize = '16px';
    }
}


export const updateThemeColors = (theme)=> {
    document.documentElement.style.setProperty('--default-background-color', theme.default_background);
    document.documentElement.style.setProperty('--default-shadow-color', theme.default_shadow);
    document.documentElement.style.setProperty('--background-text-primary-color', theme.background_text_primary);
    document.documentElement.style.setProperty('--background-text-secondary-color', theme.background_text_secondary);
    document.documentElement.style.setProperty('--button-text-primary-color', theme.button_text_primary);
    document.documentElement.style.setProperty('--button-text-secondary-color', theme.button_text_secondary);
    document.documentElement.style.setProperty('--button-background-primary-color', theme.button_background_primary);
    document.documentElement.style.setProperty('--button-background-selected-color', theme.button_background_selected);
    document.documentElement.style.setProperty('--button-nav-background-color', theme.button_nav_background);
    document.documentElement.style.setProperty('--button-nav-background-color-dark', theme.button_nav_background_dark);
    document.documentElement.style.setProperty('--clickable-text-color', theme.clickable_text);
    document.documentElement.style.setProperty('--clickable-text-color-dark', theme.clickable_text_dark);
    if(theme.shadowbuttons){ //add shadowing to buttons with very dark backgrounds
        document.documentElement.style.setProperty('--shadow-button-amount', '5px');
    }
    document.documentElement.style.setProperty('--empty-input-background-color', theme.default_background);
}

export const tallyServiceQuote = (cat_addons, fees) => { //requires .price_low and .price_high properties!
    console.log("Tally Quote")
    console.log(cat_addons)
     // if (this.props.values_quote.floorquote === null) {
     //     return -1; //quote not ready
     // }
     let checkmarked = cat_addons;
    //console.log('checkmarked')
    //console.log(checkmarked)

    let sum_low = 0;
    let sum_high = 0;
    let sum_exact = 0;
     if(checkmarked ){
         for (let i = 0; i < checkmarked.length; i++){
            //console.log(checkmarked[i])
            if(checkmarked[i].flat === 'Call Us'){ //item requires calling for a quote, don't include in tally
                return "call";
            }
            if(!isNaN(checkmarked[i].price_low) && checkmarked[i].price_low !== null){ //TODO pass in actual current package
                sum_low += parseFloat(checkmarked[i].price_low);
            }
            if(!isNaN(checkmarked[i].price_high) && checkmarked[i].price_high !== null){
                sum_high += parseFloat(checkmarked[i].price_high);
            }
            if(!isNaN(checkmarked[i].price_exact) && checkmarked[i].price_exact !== null){
                sum_exact += parseFloat(checkmarked[i].price_exact);
            }
         }
     }
    //console.log('base, addons, and color sum: ' + sum)
    sum_low += fees; //calculated on backend
    sum_high += fees;
    //console.log('base, addons, color, and fees sum: ' + sum)
     if (isNaN(sum_low) || isNaN(sum_high)){
         console.error("Error: tallied result was Nan")
     }
    console.log('final sum: ' + sum_low + " - " + sum_high, sum_exact)
     return [sum_low, sum_high, sum_exact];
 }