import React, {Component} from 'react';
import axios from 'axios';
import Header from './Header.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faPlus, faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import ScheduleContact from './ScheduleContact.js';
import Branch from './general_questions/Branch.js';
import ProgressBar from './questions/ProgressBar.js';
import ErrorBoundary from './general_questions/components/ErrorBoundry.js';
import Instructions from './general_questions/Instructions.js';
import ContinueFooter from './questions/ContinueFooter.js';
import MultipleChoiceText from './general_questions/MultipleChoiceText.js';
import StepCounter, { StepCounterQuestion } from './general_questions/StepCounterQuestion.js';
import MakeStatement from './general_questions/MakeStatement.js';
import AngleSelector from './general_questions/AngleSelector.js';
import Places from './general_questions/CollectAddressHook.js';
import CollectContact from './questions/CollectContact.js';
import ReviewSelections from './pianomoving_questions/ReviewSelections.js';
import QuotePackages_v2 from './general_questions/QuotePackages_v2.js';
import ConditionChooser from './general_questions/ConditionChooser.js';
import full_turn_stair from './pianomoving_questions/images/full_turn_stair.png';
import quarter_turn_stair from './pianomoving_questions/images/quarter_turn_stair.png';
import grand_piano from './pianomoving_questions/images/grand_piano.png';
import upright_piano from './pianomoving_questions/images/upright_piano.png';
import ConditionChooserWithDropDown from './general_questions/ConditionChooserWithDropdown.js';
import JobType from './general_questions/JobType.js';
import { sendToInpersons, setMobileStyling, updateThemeColors } from '../Helpers.js';
import FreeFormTextInput from './general_questions/FreeFormTextInput.js';
import { LoadScript } from '@react-google-maps/api';
import MakeStaticStatement from './general_questions/MakeStaticStatement.js';
import { ScheduleModal } from './general_questions/modals/ScheduleModal.js';

// MVP Time log:
// 9 FEB: 30 minutes (React Survey BoilerPlate, create new company info function)
// 12 FEB: 6 hours(Piano Survey front end)
// 13-16 FEB: (start 8:45am)

//steps to make embed friendly:
//Update component didmoutn, didupdate methods, and add const is_mobile_display = this.props.is_mobile_display || this.props.embedded;
//pass embedded as a prop to Footercontact through values_business


export class PianoSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //------------------------------//
            //** General State Conditions **//
            pages: ['Branch'], //array history of pages
            mounted: false, //component mounted
            instructions_complete: null, //key of instructions completed
            reviewed_selections: false, //if user has reviewed selections
            //---------------------//
            //** Customer Inputs **//
            jobtype: {title:'piano moving'}, //type of job, from jobtype_options
            email: null, //customer email
            phone: "", //customer phone
            customername: null, //customer name
            preferredcontact: null, //preferred contact method
            branch: null, // company branch
            estimate_reason: null, //reason needed for an in-person estimate
            job_images: [], //base64 encoded images of job
            number_stairs_batch: 0, //number of stairs in agiven batch (NOT total)
            stair_turn: 0, //angle of turn in degrees
            address: null, //contact address, set to match address_start by default, but customer can change
            address_start: null, //starting address for distance calculation
            address_end: null, //destination address for distance calculation
            sequential_input: [], //array of objects with .key, .title, .value that are pushed in order of user input, to be replayed at end of survey
            moving_object_type: null, //type of piano
            moving_object_size: null, //size of piano
            other_info: null, //other information provided by customer
            //--------------------------//
            //** Business Information **//
            businessid: props.subdomain, //table ref
            businesslogo: null, //s3 logo key
            businesslogo_horizontal: null, //s3 logo key
            businessname: '', //company name
            businessaddress: '', 
            jobtype_options: null, //array of objects with .title
            branch_options: [], //array of objects with .name, .phone, .email, .address (in format `${street}, ${city}, ${state} ${zipCode}` for GoogleMapsAPI)
            base_items: [], //array of objects with .title,  .short, .long, .coef (num), .flat (num)
            addon_items: [], //array of objects with .title,  .short, .long, .coef (num), .flat (num)
            inpersons: {}, //object with state conditions, sorted by survey Page, to send to contact page if true
            nodisplays: {}, //pages to hide, with conditions to do so
            quote_range: null, //display final quote in a range of +/- this % value
            homepage: '', //link back to company's homepage
            marketing_tag: null, //company's marketing tagline in footer
            custom_theme: null, //company's custom theme color
            industry: 'piano-moving',   //company's industry. Must match DB table name!
            number_tight_corners: 0, //number of Tight Turns
            number_grass_dirt: 0, //number of grass/dirt areas
            travel_distance_mode: null, //mode of travel distance calculation. object with {stageA (string), stageB (string), stageC (string)} //HQ->pointA, pointA->pointB, pointB->HQ
            travel_distance_max_quotable: null, //max distance to quote travel
            travel_distance_total: null, //total distance of user's move
            afterquote_url : null, //url to redirect to after quote is viewed (optional)
            //-----------------------//
            //** Quote Information **//
            quoteid: '', //table key
            quote_loaded: false,
            fees: null, //extra costs for job not explicity broken down in package
            //-----------------------//
            //** General State Conditions **//
            EMAIL_REGEX : new RegExp(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/
            ),
            PHONE_REGEX : new RegExp(  //1-xxx-xxx-xxxx, xxx-xxx-xxxx, xxxxxxxxxx, xxx xxx xxxx etc.
                /^(?:1(?:[ -]?\(\d{3}\)[ -]?\d{3}[ -]?\d{4}|\d{3}[ -]?\(\d{3}\)[ -]?\d{4})|\(\d{3}\)[ -]?\d{3}[ -]?\d{4}|\d{10}|\d{11}|\d{3}[ -]?\d{3}[ -]?\d{4})$/
            ),
            map_loaded: false,
            isdemo: props.subdomain === 'demo',
            external_lead_sent: false,
        };
    }

    componentDidMount = () => {
        const id = this.props.subdomain;
        const {businessid} = this.state;
        if(!businessid){this.initBizID(id)};
        if(businessid){this.getCompanyInfo(businessid)};
        setMobileStyling(this.props.embedded || this.props.is_mobile_display);
        this.checkValues();
        this.setState({ mounted: true });
    }

    componentDidUpdate = (prevProps, prevState) => {
        setMobileStyling(this.props.embedded || this.props.is_mobile_display);
    }


    updateWindowDimensions = () => {
        this.setState({ win_width: window.innerWidth, win_height: window.innerHeight }); 
    }

    setMapLoaded = () => {
        console.log("Map loaded");
        this.setState({map_loaded: true});
    }

    initBizID = (id) => {
        this.setState({
            businessid: id,
        });
    }

    nextPage = (next, skip_current=false, check_values = true) => {
        const {pages} = this.state;
        const updated_pages = [...pages];
        if (skip_current){
            updated_pages.pop()
        }
        console.log('Next page: ' + next)
        updated_pages.push(next);
        if (check_values){
            this.setState({
                pages: [...updated_pages]
            }, this.checkValues());
        } else {
            this.setState({
                pages: [...updated_pages]
            });
        }
    };

    prevPage = () => { //custom behavior: if the previous page was one that calls for a sequential input (and the person has clicked "back"), remove the last input from the array.
        //TODO: hard to find away where going back and removing last input makes sense. Makes more sense to add "start over" button?
        const {pages, sequential_input} = this.state;
        const updated_pages = [...pages];
        const updated_sequential_input = [...sequential_input];
        // const last_page = 
        updated_pages.pop();
        // const current_page = updated_pages[updated_pages.length - 1];
        // let last_input = updated_sequential_input[updated_sequential_input.length - 1];
        // let removed_sequential_input = false;
        // if (last_input){
        //     // if (current_page === 'Obstacles' && ( last_input.title === 'Obstacle' || last_input.title === 'Tight Turn' || last_input.title === 'Grass or Dirt Section') ){ //if last page was an obstacle without a followup page, remove the last input
        //     //     //updated_sequential_input.pop();
        //     //     //removed_sequential_input = true;
        //     // } else if (current_page.includes('Stairs') && last_input.title === 'Stairs'){ //if last page was stairs without a followup page, remove the last input
        //     //     updated_sequential_input.pop();
        //     //     removed_sequential_input = true;
        //     // } else if (current_page === 'Turn Angle' && last_input.title === 'Turn'){ //if last page was a turn with a followup page, remove the last input
        //     //     updated_sequential_input.pop();
        //     //     removed_sequential_input = true;
        //     // }
        //     // if (current_page.toLowerCase().includes('address') && last_input.title.toLowerCase() !== 'Obstacle') {
        //     //     do { //remove all inputs until the next address input is reached
        //     //         updated_sequential_input.pop();
        //     //         removed_sequential_input = true;
        //     //     } while (updated_sequential_input[updated_sequential_input.length - 1].title.toLowerCase().includes('address'));
        //     // }
        // }
        // console.log('Going back. Removed sequential input: ' + removed_sequential_input);
        this.setState({
            pages: [...updated_pages],
            sequential_input: [...updated_sequential_input]
        }, this.checkValues());
        
    };

    restartSurvey = () => {
        this.setState({
            pages: ['Branch'],
            instructions_complete: null,
            reviewed_selections: false,
            //mounted: false,
            sequential_input: [],
            quoteid: '',
            quote_loaded: false,
            fees: null,
            number_stairs_batch: 0,
            stair_turn: 0,
            number_tight_corners: 0,
            number_grass_dirt: 0,
            address: null,
            address_start: null,
            address_end: null,
            moving_object_type: null,
            moving_object_size: null,
        });
    }

    handleChange = input => e => {
        //console.log(e.target.id);
        console.log(e.currentTarget.id);
        let st = e.currentTarget.id;
        let val = e.target.value;
        if (val !== null && val !== '' && typeof val !== 'undefined'){
            st = e.target.value;
        }
        console.log("Handlechange")
        console.log(st)
        this.setState({[input]: st});
    };

    handleChangeNext = (next,input) => e => {
        console.log(input);
        let st = e.currentTarget.id;
        let val = e.target.value;
        if (val !== null && val !== '' && typeof val !== 'undefined'){
            st = e.target.value;
        }

        this.setState({[input]: st}, this.nextPage(next));
    };

    handleChangeObject = (input, ob) => {
        this.setState({[input]: ob});
    };

    handleChangeObjectNext = (next, input, ob, skip=false) => {
        this.setState({[input]: ob}, this.nextPage(next, skip));
    };

    getObstacleDisplayName = (obstacle_type) => {
        if (obstacle_type === 'stairs'){
            return 'Stairs';
        } else if (obstacle_type === 'stair_turn'){
            return 'Turn on Stairs';
        } else if (obstacle_type === 'number_tight_corners'){
            return 'Tight Turn';
        } else if (obstacle_type === 'number_grass_dirt'){
            return 'Grass or Dirt Section';
        } else if (obstacle_type === 'number_stairs_batch'){
            return 'Stairs';
        } else {
            return 'Obstacle';
        }
    }

    handleObstacleChoice = (input, value, next=null, skip=false) => {
        console.log("handling obstacle choice");
        console.log("input: " + input);
        console.log("ob value: " + value);
        console.log("next: " + next);
        if (input && value){
            // let value = this.state[input];
            // console.log(value);
            // if (typeof value !== 'number' && value){
            //     value = parseFloat(value);
            // }
            // value = value + 1;
            // console.log(value);
            if(input === 'number_stairs_batch' || input === 'stair_turn'){
                this.setState({[input]: 0}); //reset stairs to 0
            }
            if (value === 'start' || value === 'destination'){ //only add address to sequential input if it doesn't already exist
                const {sequential_input} = this.state;
                const updated_sequential_input = [...sequential_input];
                const index = updated_sequential_input.findIndex(obj => obj.title === value);
                if (index !== -1){
                    if (next){
                        this.nextPage(next, skip);
                    } else {
                        return;
                    }
                } //else continue
            }
            if (next){
                this.addToSequentialInput(input, value);
                this.nextPage(next, skip);
            } else {
                this.addToSequentialInput(input, value);
            }
        }  else {
            if (next){
               this.nextPage(next, skip);
            }
        }
    }

    getGoogleMapsDistance = async (origin, destination, mode) => {
        origin = JSON.stringify(origin);
        destination = JSON.stringify(destination);
        console.log("getGoogleMapsDistance");
        console.log(origin);
        console.log(destination);
        const url = `https://4swymrxhqqspy74gtaxsocxis40sljfp.lambda-url.us-east-2.on.aws/?start=${encodeURIComponent(origin)}&end=${encodeURIComponent(destination)}&mode=${mode}`;
        try {
            const response = await axios.get(url); // Use await here
            console.log(response);
            if (response.status === 200) {
                console.log(response.data);
                const parsedData = parseFloat(response.data); // Adjust this line as needed
                console.log(parsedData);
                return parsedData; // This value will now be returned correctly
            } else {
                console.error('Error in google maps api request.');
                return null; // Return null or some error indication as needed
            }
        } catch (error) {
            console.error('Error fetching data: ', error);
            return null; // Return null or some error indication as needed
        }
    };
    

    handleUpdateDistance = async (state_var, distance_value, new_address) => {
        console.log("handleAddDistance");
        console.log(new_address);
        const {travel_distance, travel_distance_mode, travel_distance_max_quotable} = this.state;
        const updated_travel_distance = {...travel_distance};
        let total_distance = 0;
        let distance_name;
        if (state_var === 'address_start'){
            distance_name = 'stageA';
            total_distance = distance_value;
        } else if (state_var === 'address_end'){
            distance_name = 'stageB';
            const return_trip_distance = await this.getGoogleMapsDistance(new_address, this.state.branch.hq_coord, travel_distance_mode);
            total_distance = distance_value + travel_distance.stageA;
            if (return_trip_distance !== null) {
                updated_travel_distance['stageC'] = return_trip_distance;
                total_distance = distance_value + return_trip_distance + travel_distance.stageA;
            } else {
                total_distance = 'Unable to Calculate';
                console.error('Error fetching return trip distance, no return trip value returned.');
            }
        }
        updated_travel_distance[distance_name] = distance_value;
        this.setState({travel_distance: {...updated_travel_distance}, travel_distance_total: total_distance});
    }

    generateKey = () => {
        return Math.random().toString(16).slice(2);
    }

    addToSequentialInput = (obstacle_type, value) => {
        console.log("addToSequentialInput");
        const {sequential_input} = this.state;
        const updated_sequential_input = [...sequential_input];
        const title = this.getObstacleDisplayName(obstacle_type);
        updated_sequential_input.push({title: title, value: value, key: this.generateKey()});
        console.log(updated_sequential_input);
        this.setState({
            sequential_input: [...updated_sequential_input]
        });
    };

    removeFromSequentialInput = (key) => e => {
        e.preventDefault();
        console.log("removeFromSequentialInput");
        const {sequential_input} = this.state;
        const updated_sequential_input = [...sequential_input];
        const index = updated_sequential_input.findIndex(obj => obj.key === key);
        updated_sequential_input.splice(index, 1);
        console.log(updated_sequential_input);
        this.setState({
            sequential_input: [...updated_sequential_input]
        });
    };

    editSequentialInput = (key) => e => {
        console.log("editSequentialInput");
        e.preventDefault();
        const {sequential_input} = this.state;
        const value = e.target.value;
        const updated_sequential_input = [...sequential_input];
        const index = updated_sequential_input.findIndex(obj => obj.key === key);
        updated_sequential_input[index].value = value;
        console.log(updated_sequential_input);
        this.setState({
            sequential_input: [...updated_sequential_input]
        });
    };


    handleChangeEstimateReason = (reason, state_name=null, state_val=null) => {
        console.log("handleChangeEstimateReason");
        console.log(reason);
        if(state_name && state_val){ //update estimate reason plus another state element
            this.setState({estimate_reason: reason, [state_name]: state_val}, this.nextPage('Schedule'));
        }
        else { //just update the estimate reason
            this.setState({estimate_reason: reason}, this.nextPage('Schedule'));
        }
    };

    handleSaveQuote = async (tallied, included) => {
        /**MATOMO ANALYTICS**/
        //_paq.push(['trackEvent', 'Contact', 'Email Link Click', 'name@example.com']);
        window._paq.push(['trackGoal', 1]);
        /**END MATOMO **/

        //var id = Math.random().toString(16).slice(2);
        const { jobtype, businessid, quoteid, email , customername, phone, branch, businessname, businesslogo, industry, travel_distance} = this.state;
        var addons = 'none';
        var base = '';

        if (included){
            for (var i = 0; i < included.length; i++){
                if (i > 0){
                    base = base + '; ' + included[i].title;
                } else if (i === 0) {
                    base = included[i].title;
                }
            }
        }
        console.log(included);
        console.log(base);
        console.log(addons);
        const response = await axios.post(
            //'https://dilqskv43l3zmifawahozxuq4m0zqmbo.lambda-url.us-east-2.on.aws/', // !for testing purposes only!
          'https://fok7u3r3f7.execute-api.us-east-2.amazonaws.com/prod/genQuoteFunction/savequote',
          { 
              id: quoteid,
              industry: industry,
              email: email,
              phone: phone, 
              businessname: businessname,
              businessid: businessid,
              businessphone: branch.phone,
              businessemail: branch.email,
              businesslogo: businesslogo,
              totalquote: tallied,
              addons: addons,
              included: base,
              customername: customername,
              branch: branch.name,
            }
        );
        console.log(response);
    }

    handleScheduleJob = async (tallied, services, included) => {
        /**MATOMO ANALYTICS**/
        //_paq.push(['trackEvent', 'Contact', 'Email Link Click', 'name@example.com']);
        window._paq.push(['trackGoal', 2]);
        /**END MATOMO **/

        const { jobtype, businessid, quoteid, email, phone, customername, businessname, branch, businesslogo, industry, preferredcontact, address } = this.state;
        var addons = 'none';
        var base = '';
        console.log(included);
        console.log(base);
        const response = await axios.post(
          'https://fok7u3r3f7.execute-api.us-east-2.amazonaws.com/prod/genQuoteFunction/schedulejob',
          { 
            id: quoteid,
            industry: industry,
            email: email,
            phone: phone, 
            businessname: businessname,
            businessid: businessid,
            businessphone: branch.phone,
            businessemail: branch.email,
            businesslogo: businesslogo,
            totalquote: tallied,
            addons: addons,
            included: base,
            customername: customername,
            branch: branch.name,
            preferredcontact: preferredcontact,
            streetaddress: address,
        }
        );
        console.log(response);
    }

    
    handleViewedQuote = async (tallied) => {
        console.log("handleViewedQuote");
       /**MATOMO ANALYTICS**/
        //_paq.push(['trackEvent', 'Contact', 'Email Link Click', 'name@example.com']);
        window._paq.push(['trackGoal', 4]);
        /**END MATOMO **/

        //var id = Math.random().toString(16).slice(2);
        const { businessid, travel_distance_mode, quoteid, email , custom_theme, customername, phone, branch, businessname, businesslogo, address, affiliate, moving_object_type, moving_object_size, sequential_input, address_start, address_end, industry, travel_distance, other_info, urgency} = this.state;
        var base = '';
        var addons = 'none';
        console.log(addons);
        console.log(base);
        console.log("tallied: " + tallied);
        const response = await axios.post(
          'https://fok7u3r3f7.execute-api.us-east-2.amazonaws.com/prod/genQuoteFunction/save-lead',
          { 
                id: quoteid,
                industry: industry,
                email: email,
                phone: phone, 
                streetaddress: address,
                businessname: businessname,
                businessid: businessid,
                businessphone: branch.phone,
                businessemail: branch.email,
                businesslogo: businesslogo,
                //basecost: tallied,
                totalquote: tallied, //in this case, will be last quote selected by customer
                addons: addons,
                included: base,
                customername: customername,
                branch: branch.name,
                affiliate: (affiliate && affiliate.should_refer) ? affiliate : '',
                other_info: other_info,
                urgency: urgency,
                custom_theme: custom_theme,

                //piano-sepecific quote information
                moving_object_size: moving_object_size,
                moving_object_type: moving_object_type,
                address_start: address_start,
                address_end: address_end,
                travel_distance: travel_distance,
                travel_distance_mode: travel_distance_mode,
                sequential_input: sequential_input,
            }
        );
        console.log(response);
        if (response.data && response.data.id){
            console.log("Quote ID: " + response.data.id);
            this.setState({quoteid: response.data.id});
        }
    }

    handleScheduleEstimate = async (contact, lead_type) => {
        /**MATOMO ANALYTICS**/
        //_paq.push(['trackEvent', 'Contact', 'Email Link Click', 'name@example.com']);
        window._paq.push(['trackGoal', 3]);
        /**END MATOMO **/

        const { estimate_reason, travel_distance_mode, industry, jobtype, businessid, businessname, branch, businesslogo, moving_object_size, moving_object_type, address_start, address_end, travel_distance, sequential_input, affiliate } = this.state;
        var colors = null;
        if(this.state.colors){
            colors = '';
            for (var i = 0; i < this.state.colors.length; i++){
                colors = colors + this.state.colors[i].style + ' ' + this.state.colors[i].title + '; '
            }
            console.log(colors);
        }
        const response = await axios.post(
          'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/dev/concreteQuoteFunction/scheduleestimate',
          { 
              leadtype: lead_type, //i.e. call, estimate, job, error
              jobtype: jobtype.title,
              email: contact.email,
              phone: contact.phone, 
              businessname: businessname,
              businessid: businessid,
              businessphone: branch.phone,
              businessemail: branch.email,
              businesslogo: businesslogo,
              customername: contact.customername,
              preferredcontact: contact.preferredcontact,
              streetaddress: contact.streetaddress,
              estimate_reason: estimate_reason,
              industry: industry,

              branch: branch.name,
              affiliate: (affiliate && affiliate.should_refer) ? affiliate : '',

              //piano-sepecific quote information
              moving_object_size: moving_object_size,
              moving_object_type: moving_object_type,
              address_start: address_start,
              address_end: address_end,
              travel_distance: travel_distance,
              travel_distance_mode: travel_distance_mode,
              sequential_input: sequential_input,
            }
        );
        console.log(response);
    }

    // lead generated for external company
    handleReportExternalLead = async () => {

        const { estimate_reason, travel_distance_mode, industry, jobtype, businessid, businessname, branch, businesslogo, moving_object_size, moving_object_type, address_start, address_end, travel_distance, sequential_input, affiliate, phone, email, address, preferredcontact, customername, EMAIL_REGEX, PHONE_REGEX } = this.state;

        if(!email || !EMAIL_REGEX.test(email) || !phone || !PHONE_REGEX.test(phone)|| !customername || !address || !preferredcontact){
            this.setState({ externallead_inputflag: true });
        } 

        const response = await axios.post(
          'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/dev/concreteQuoteFunction/ext_lead',
          { 
              leadtype: 'estimate', //i.e. call, estimate, job, error
              jobtype: jobtype.title,
              email: email,
                phone: phone, 
                streetaddress: address,
                preferredcontact: preferredcontact,
                customername: customername,
              businessname: businessname,
              businessid: 'external_lead_reporter',
              businessphone: branch.phone,
              businessemail: 'jason@thefairquote.com',
              businesslogo: businesslogo,
              estimate_reason: estimate_reason,
              industry: industry,

              branch: branch.name,
              affiliate: (affiliate && affiliate.should_refer) ? affiliate : '',

              //piano-sepecific quote information
              moving_object_size: moving_object_size,
              moving_object_type: moving_object_type,
              address_start: address_start,
              address_end: address_end,
              travel_distance: travel_distance,
              travel_distance_mode: travel_distance_mode,
              sequential_input: sequential_input,
            }
        );
        console.log(response);
        this.setState({external_lead_sent: true});
    }

    getCompanyInfo = async (id) => {
        //const {businessid, businessname, branch_options, jobtype_options, base_items, addon_items, coat_options } = this.state;
        console.log("requesting company info...");
        console.log(id)
        const {industry} = this.state;
        const response = await  axios.get(
            'https://ekdymqwbk5bj7jxf7rx6pbidfy0fllos.lambda-url.us-east-2.on.aws/',
            { 
                params: { business: id, industry: industry} 
            }
        );
        const data = JSON.parse(response.data).body;
        console.log(data);
        const marketing_footer = (data.marketing_tag) ? JSON.parse(data.marketing_tag) : null;
        const custom_theme = (data.custom_theme) ? JSON.parse(data.custom_theme) : null;
        const travel_coef = JSON.parse(data.travel_coef);

        this.setState({
            businessname: data.name,
            businesslogo: data.logo,
            branch_options: JSON.parse(data.branches),
            jobtype_options: (data.jobtypes) ? JSON.parse(data.jobtypes) : null,
            businesslogo_horizontal: data.logo_horizontal,
            //servicetype_options: JSON.parse(data.services),
            //base_items: JSON.parse(data.base),
            addon_items: JSON.parse(data.addons),
            inpersons: (data.Inpersons) ? JSON.parse(data.Inpersons) : null,
            nodisplays: (data.nodisplays) ? JSON.parse(data.nodisplays) : null,
            afterquote_url: data.afterquote_url,
            quote_range: data.quote_range,
            homepage: data.homepage,
            marketing_tag: marketing_footer,
            custom_theme: custom_theme,
            travel_distance_mode: JSON.parse(data.travel_coef).unit,
            travel_distance_max_quotable: Math.max(travel_coef.additional_mileage.radius, travel_coef.local_mileage.radius),
        }, () => {        if(this.state.custom_theme){
            console.log("updating theme colors");
            updateThemeColors(this.state.custom_theme);
        }});
    }

    getDBQuote = async () => {

        const { businessid, addon_items, base_items, travel_distance, fees, address_end, address_start, sequential_input} = this.state;

        const response = await axios.post(
            'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/dev/generalQuoteFunction/piano-quote', //$LATEST
            { 
                business: businessid,
                addons : addon_items,
                base : base_items,
                travel_distance: travel_distance,
                address_end: address_end,
                address_start: address_start,
                sequential_input: sequential_input,
                moving_object_size: this.state.moving_object_size,
                moving_object_type: this.state.moving_object_type,
            }
        );
        const data = JSON.parse(response.data);
        console.log(data);

        this.setState({
            quoteid: data.id,
            addon_items: data.addons,
            base_items: data.base,
            fees: data.other_fees,
            quote_loaded: true,
        });
      }

    handleInstructionsComplete = (key) => {
        this.setState({instructions_complete: key});
    }

    handleReviewedSelections = () => {
        this.setState({reviewed_selections: true});
    }

    // checkInpersons = (selected_value, selected_name, referring_page) => {
    //     const {inpersons} = this.state;
    //     sendToInpersons(selected_value, selected_name, this.state, inpersons, referring_page);
    //     //TODO start here...how to inpersons with AddressHook?


    checkValues= () => { //ensures no conflicting user answers are submitted to DB
        console.log("checking values");
        console.log(this.state);
        const {pages, address, address_start, travel_distance_max_quotable, travel_distance_total, estimate_reason} = this.state;
        const travel_estimate_reason = 'Per your settings, this travel distance requires a call to quote';
        if (!this.state.branch && this.state.branch_options && this.state.branch_options.length === 1){ //if only one branch option, set state to reflect current branch
            this.setState ({branch: this.state.branch_options[0]}); 
        } 
        if (pages[pages.length -1] === 'Instructions (Destination)'){
            console.log("last page");
            this.setState({last_round_of_input: true});
        } 
        if (!pages.includes('Instructions (Destination)') && pages[pages.length -1].includes('Address')){
            console.log("not last page");
            this.setState({last_round_of_input: false});
        }
        //if a starting address has been entered and the user has not yet entered a [contact] address, set the contact address to match the starting address
        if (address_start && !address){
            this.setState({address: address_start});
        }

    };

    findIndexByProperty(arr, propertyName, propertyValue) { //find index of object in array give a single property
        const index = arr.findIndex(obj => obj[propertyName] === propertyValue);
        return index;
      }

    handleShowModal = (modal) => {
        this.setState({show_modal: modal});
    }


    render() {
        const is_mobile_display = this.props.is_mobile_display || this.props.embedded;
        const container_class = (this.props.embedded) ? 'container embed' : 'container';
        const { pages, last_round_of_input} = this.state;
        const {sqfootage, businessid, quoteid, driveway_status, driveway_condition, email, phone, customername, address, preferredcontact, branch, jobLatLng, jobtype, job_images} = this.state;
        const {businessname, businesslogo, branch_options, jobtype_options, base_items, addon_items, inpersons, quote_range, homepage, nodisplays, marketing_tag, industry, afterquote_url} = this.state;
        const { fees, win_height, win_width, number_stairs, number_tight_corners, number_grass_dirt, address_start, address_end, moving_object_type, moving_object_size, sequential_input , travel_distance_mode, other_info, urgency, businesslogo_horizontal} = this.state;
        const values_general = { 
            pages 
        };
        const values_customer = { //Customer Modified
            branch,
            jobtype,
            email,
            phone,
            customername,
            address,
            preferredcontact,
            jobLatLng,
            job_images,
            number_stairs,
            number_tight_corners,
            number_grass_dirt,
            address_start,
            address_end,
            moving_object_type,
            moving_object_size,
            sequential_input,
            other_info,
            urgency
        };
        const values_business = { //Company-specific Information
            businessid,
            businessname,
            businesslogo,
            branch_options,
            jobtype_options,
            nodisplays,
            base_items,
            addon_items,
            inpersons,
            quote_range,
            homepage,
            marketing_tag,
            industry,
            singlepackage : true,
            travel_distance_mode,
            embedded: this.props.embedded,
            businesslogo_horizontal,
            afterquote_url
        };
        const values_quote = {  //modified by Quote API
            quoteid, 
            fees
        };
        let nextpage;
        let returnedPage = null;
        const current_page = pages[pages.length -1];
        const moving_object_name = (moving_object_type) ? 'piano' : 'organ';
        const schedule_modal = <ScheduleModal
                                    activemod={this.state.show_modal}
                                    setCustomerEmail={this.handleChange('email')}
                                    setCustomerPhone={this.handleChange('phone')}  
                                    setCustomerName={this.handleChange('customername')} 
                                    //setCustomerAddress={handleChange('address')}
                                    handleChangeObject={this.handleChangeObject}
                                    setPreferred={this.handleChange('preferredcontact')} 
                                    handleClose = {this.handleShowModal}
                                    handleSubmit={this.handleReportExternalLead} 
                                    showinputflag={this.state.externallead_inputflag}
                                    title={'Partner Quote Request'} 
                                    //content={schedulecontent} 
                                    email={values_customer.email}
                                    email_regex={this.state.EMAIL_REGEX}
                                    phone={values_customer.phone}
                                    phone_regex={this.state.PHONE_REGEX}
                                    customername={customername}
                                    preferredcontact={preferredcontact}
                                    address={address}
                                    emailsent={this.state.external_lead_sent}
                                    //afterquote_link={values_business.afterquote_url}
                                    isdemo={false}
                                    values_business={values_business}
                                    values_customer={values_customer}
                                    />


        if (current_page === "Schedule"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '100'
                    />
                    <ScheduleContact
                        pagetype={'estimate'} 
                        handleScheduleEstimate={this.handleScheduleEstimate}
                        handleChangeObject={this.handleChangeObject} 
                        values_customer={values_customer}
                        values_business={values_business}
                        win_width = {win_width}        
                        is_mobile_display = {is_mobile_display}      
                        email_regex = {this.state.EMAIL_REGEX}
                        phone_regex = {this.state.PHONE_REGEX}     
                    />
                </div>
            )
        }
        else if (current_page === "Branch"){
            console.log("rendering branch");
            if (!this.state.branch_options || !this.state.mounted || this.state.branch_options && this.state.branch_options.length === 0){
                console.log("loading branch");
                returnedPage = (
                    <div className={container_class} key={this.state.branch_options}>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <FontAwesomeIcon 
                            icon={faSyncAlt}
                            class='fa-spin spinner'
                        />
                    </div>
                )
            } else {
                console.log("branch loaded");
                returnedPage = (
                    <div className={container_class}>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '0'
                        />
                        <Branch
                            key = {JSON.stringify(this.state.branch_options)}
                            handleChangeObjectNext={this.handleChangeObjectNext}
                            showpage = {this.state.branch_options.length !== 1}
                            nextPage={this.nextPage}
                            pages={pages}
                            next = {'Job Type'}
                            values_customer={values_customer} 
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}   
                        />
                    </div>
                )
            }
        }
        else if (current_page === "Job Type"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        restartSurvey={this.restartSurvey}
                        nav_type = 'none'
                        values_general={values_general}
                        values_business={values_business}   
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '5'
                    />
                    <JobType
                        key = {current_page}
                        nextPage={this.nextPage}
                        handleChangeObjectNext={this.handleChangeObjectNext}
                        handleChangeEstimateReason={this.handleChangeEstimateReason}
                        next = {'Piano Type'}
                        showpage = {true}
                        values_customer={values_customer} 
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                    />
                </div>
            )
        }   
        else if (current_page === "Piano Type"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        restartSurvey={this.restartSurvey}
                        nav_type = 'none'
                        values_general={values_general}
                        values_business={values_business}   
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '10'
                    />
                    <ConditionChooserWithDropDown
                        page_title = {current_page}
                        handleChangeObject={this.handleChangeObject}
                        handleChangeEstimateReason={this.handleChangeEstimateReason}
                        estimate_reason = 'Based on your FairQuote settings, this piano size or type requires an estimate over the phone.'
                        showpage = {jobtype.title === 'Piano Moving'}
                        nextPage={this.nextPage}
                        default_next = {'Organ Type'}
                        pages={pages}
                        header_question = {'What kind of piano do you have?'}
                        default_state_var = {'moving_object_type'}
                        condition_levels = {
                            [                                     
                                {
                                    value: 'upright',
                                    picture: upright_piano,
                                    description: 'Upright',
                                    dropdown_options: [
                                        {
                                            title: `Small Upright <37"`,
                                            value: `Small Upright <37"`,
                                            state_var: 'moving_object_size',
                                        },
                                        {
                                            title: `Medium Upright <48"`,
                                            value: `Medium Upright <48"`,
                                            state_var: 'moving_object_size',
                                        },
                                        {
                                            title: `Large Upright >48"`,
                                            value: `Large Upright >48"`,
                                            state_var: 'moving_object_size',
                                        },
                                        {
                                            title: `Upright Grand`,
                                            value: `Upright Grand`,
                                            state_var: 'moving_object_size',
                                        },
                                        {
                                            title: `Other`,
                                            value: `Other`,
                                            state_var: 'moving_object_size',
                                            nextpage: "Schedule"
                                        }
                                    ]
                                },
                                {
                                    value: 'grand',
                                    picture: grand_piano, //src
                                    description: 'Grand',
                                    dropdown_options: [
                                        {
                                            title: `Grand 4' to 5'`, 
                                            value: `Grand 4' to 5'`,
                                            state_var: 'moving_object_size',
                                        },
                                        {
                                            title: `Grand 5' to 6'`, 
                                            value: `Grand 5' to 6'`,
                                            state_var: 'moving_object_size',
                                        },
                                        {
                                            title: `Grand 6' to 7'`, 
                                            value: `Grand 6' to 7'`,
                                            state_var: 'moving_object_size',
                                        },
                                        {
                                            title: `Grand 7' to 8'`, 
                                            value: `Grand 7' to 8'`,
                                            state_var: 'moving_object_size',
                                        },
                                        {
                                            title: `Concert Grand`, 
                                            value: `Concert Grand`,
                                            state_var: 'moving_object_size',
                                        },
                                        {
                                            title: `Square Grand`, 
                                            value: `Square Grand`,
                                            state_var: 'moving_object_size',
                                        },
                                        {
                                            title: `Other`, 
                                            value: `Other`,
                                            state_var: 'moving_object_size',
                                            nextpage: "Schedule"
                                        }
                                        
                                        ]
                                        //nextpage?
                                    }
                                ]
                        }
                        values_customer={values_customer} 
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                    />
                    {/* <ContinueFooter
                        key={current_page}
                        nextPage={(next) => this.handleObstacleChoice('stair_turn', this.state.stair_turn, next)}
                        nextpage = {'Stairs After Landing'}
                        showpage = {true}
                        enable = {true}
                    /> */}
                </div>
            )
        }
        else if (current_page === "Organ Type"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}   
                        nav_type = 'none'
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '10'
                    />
                    <MultipleChoiceText
                        page_title = {current_page}
                        key={current_page}
                        handleChangeObjectNext={this.handleChangeObjectNext}
                        showpage = {jobtype.title === 'Organ Moving'} //display if company offers differentiated flake pricing and if flakes are selected. "Single-size" differentiates by color, not size from hybrid flake sizes.
                        nextPage={this.nextPage}
                        default_next = {'Instructions (Start)'}
                        pages={pages}
                        header_question = {"What kind of organ do you have?"}
                        default_state_var = {'moving_object_size'}
                        choices = {
                            [
                                {title: 'Mid-size Organ', value: 'Mid-size Organ'},
                                {title: 'Large Organ', value: 'Large Organ'},
                            ]
                        }
                        values_customer={values_customer} 
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                        extra_classname = 'has-continue-footer'
                    />
                </div>
            )
        }
        else if (current_page === "Instructions (Start)"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}  
                        nav_type = 'none' 
                        is_mobile_display  = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '10'
                    />
                    <Instructions
                        key = {current_page}
                        nextPage={this.nextPage}
                        next = {'Obstacles'}
                        header_statements={[
                            `Imagine yourself seated at your ${moving_object_name}. You're playing your favorite fugue by Bach, and the birds are singing...`,
                            `We'll ask you to (in your mind's eye) walk through your home from your piano out onto the driveway/street.`,
                            ``,
                            ``,
                            `Ready?`
                        ]}
                        page_title={current_page}
                        showpage = {true}
                        instructions_complete={this.state.instructions_complete === current_page}
                        instructionsComplete={() => this.handleInstructionsComplete(current_page)}
                    />
                    <ContinueFooter
                        nextPage={(next) => this.handleObstacleChoice('start', 'start', next)}
                        nextpage = {'Obstacles'}
                        showpage = {true}
                        enable = {this.state.instructions_complete === current_page}
                        alternate_text = {`Ready`}
                    />
                </div>
            )
        }
        else if (current_page === "Instructions (Destination)"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}  
                        nav_type = 'none' 
                        is_mobile_display  = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '60'
                    />
                    <Instructions
                        nextPage={this.nextPage}
                        next = {'Obstacles'}
                        header_statements={[
                            `Now picture yourself having arrived at the destination, walking from the driveway/street to your ${moving_object_name}'s beautiful new home.`,
                            ``,
                            ``,
                            `Ready?`
                        ]}
                        // header_statement2={` Now picture yourself leaving the piano and walking through your home to the driveway/street. Ready?`}
                        page_title={current_page}
                        showpage = {true}
                        instructions_complete={this.state.instructions_complete === current_page}
                        instructionsComplete={() => this.handleInstructionsComplete(current_page)}
                    />
                    <ContinueFooter
                        nextPage={(next) => this.handleObstacleChoice('destination', 'destination', next)}
                        nextpage = {'Obstacles'}
                        showpage = {true}
                        enable = {true}
                        alternate_text = {`Ready`}
                    />
                </div>
            )
        }
        else if (current_page === "Obstacles"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}   
                        nav_type = 'none'
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= {(last_round_of_input) ? '80' : '35'}
                    />
                    <MultipleChoiceText
                        page_title = {'Obstacles'}
                        handleChangeObjectNext={(next, input, ob, skip=false) => this.handleObstacleChoice(input, ob, next, skip)}
                        showpage = {true} //display if company offers differentiated flake pricing and if flakes are selected. "Single-size" differentiates by color, not size from hybrid flake sizes.
                        nextPage={this.nextPage}
                        default_next = {'Received Response'}
                        pages={pages}
                        header_question = {(number_grass_dirt || number_stairs || number_tight_corners) ? 'Continue walking until you encounter one of the following obstacles.' : 'Walk until you encounter one of the following obstacles.'}
                        default_state_var = {'obstacle_type'}
                        choices = {
                            [
                                {title: 'Step/Stairs', state_var: 'stairs', nextpage: 'Stairs'},
                                {title: 'Stairs with Turn', state_var: 'stairs_with_turn', nextpage: 'Stairs Before Landing'},
                                {title: 'Tight Turn', description: '(90 degrees)', state_var: 'number_tight_corners', nextpage: 'Recieved Response', value: 1},
                                {title: 'Uneven Surface', description: '(Grass, dirt, paver stones, etc.)', state_var: 'number_grass_dirt', nextpage: 'Recieved Response', value: 1},
                            ]
                        }
                        gpt_prompts = {[
                            {
                                display_text: `What counts as a stair or step?`,
                                gpt_query: `What counts as a stair or step for my piano quote?`,
                            },
                            {   
                                display_text: `What counts as stairs with a turn?`,
                                gpt_query: `What counts as stairs with a turn for my piano quote?`,
                            },
                            {
                                display_text: `What counts as a Tight Turn?`,
                                gpt_query: `What counts as a Tight Turn for my piano quote?`,
                            }
                        ]}
                        values_customer={values_customer} 
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                        extra_classname = 'has-continue-footer'
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        nextpage = {(!last_round_of_input) ? 'Starting Job Address' : 'Review Selections'}
                        showpage = {true}
                        enable = {true}
                        alternate_text = {(!last_round_of_input) ? (sequential_input.length === 1)? `It's a clear shot, no obstacles` : `I've Reached the Driveway/Street` : `I'm At the Piano's New Spot`} //if its not the last round of input, and no obstacles have been selected yet (start only)
                    />
                </div>
            )
        }
        else if (current_page === "Stairs"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}   
                        nav_type = 'none'
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        //if pages includes 'Instructions (Destination)', progress = 60, else 35
                        progress= {(pages.includes('Instructions (Destination)')) ? '60' : '35'}
                    />
                    <StepCounterQuestion
                        page_title = {'Stairs'}
                        handleChangeObject={this.handleChangeObject}
                        handleChangeEstimateReason={this.handleChangeEstimateReason}
                        showpage = {true}
                        nextPage={this.nextPage}
                        default_next = {'Obstacles'}
                        pages={pages}
                        header_question = {'How many stairs are there?'}
                        state_var = {'number_stairs_batch'}
                        values_customer={values_customer} 
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                        modal_intro_query = {`What counts as a stair or step for my piano quote?`}
                        inpersons={this.state.inpersons}
                    />
                    <ContinueFooter
                        nextPage={(next) => this.handleObstacleChoice('number_stairs_batch', this.state.number_stairs_batch, next)}
                        nextpage = {'Recieved Response'}
                        showpage = {true}
                        enable = {true}
                    />
                </div>
            )
        }
        else if (current_page === "Stairs Before Landing"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}   
                        nav_type = 'none'
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= {(pages.includes('Instructions (Destination)')) ? '60' : '35'}
                    />
                    <StepCounter
                        page_title = {'Stairs Before Landing'}
                        handleChangeObject={this.handleChangeObject}
                        handleChangeEstimateReason={this.handleChangeEstimateReason}
                        showpage = {true}
                        nextPage={this.nextPage}
                        default_next = {'Turn Angle'}
                        pages={pages}
                        header_question = {'How many stairs are there BEFORE the landing?'}
                        state_var = {'number_stairs_batch'}
                        values_customer={values_customer} 
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                        inpersons={this.state.inpersons}
                    />
                    <ContinueFooter
                        key={current_page}
                        nextPage={(next) => this.handleObstacleChoice('number_stairs_batch', this.state.number_stairs_batch, next)}
                        nextpage = {'Turn Angle'}
                        showpage = {true}
                        enable = {true}
                    />
                </div>
            )
        }
        else if (current_page === "Turn Angle"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}
                        nav_type = 'none'
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= {(pages.includes('Instructions (Destination)')) ? '60' : '35'}
                    />
                    {/* <AngleSelector
                        page_title = {'Turn Angle'}
                        handleChangeObject={this.handleChangeObject}
                        showpage = {true}
                        nextPage={this.nextPage}
                        pages={pages}
                        next = {'Stairs After Landing'}
                        values_customer={values_customer} 
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                        header_question = {'What is the angle of the turn?'}
                        state_var = {'stair_turn'}
                    /> */}
                    <ConditionChooser
                        page_title = {current_page}
                        handleChangeObjectNext={(next, input, ob, skip=false) => this.handleObstacleChoice(input, ob, next, skip)}
                        showpage = {true}
                        nextPage={this.nextPage}
                        default_next = {'Stairs After Landing'}
                        pages={pages}
                        header_question = {'Which image most closely matches the turn in the stairs?'}
                        default_state_var = {'stair_turn'}
                        condition_levels = {
                            [ 
                                {
                                    value: 'Quarter',
                                    picture: quarter_turn_stair, //src
                                    description: 'Stairway Quarter Turn',
                                    //nextpage?
                                },
                                {
                                    value: 'Full',
                                    picture: full_turn_stair,
                                    description: 'Stairway Full Turn',
                                }
                            ]
                        }
                        values_customer={values_customer} 
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                    />
                    {/* <ContinueFooter
                        key={current_page}
                        nextPage={(next) => this.handleObstacleChoice('stair_turn', this.state.stair_turn, next)}
                        nextpage = {'Stairs After Landing'}
                        showpage = {true}
                        enable = {true}
                    /> */}
                </div>
            )
        }
        else if (current_page === "Stairs After Landing"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}   
                        nav_type = 'none'
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= {(pages.includes('Instructions (Destination)')) ? '60' : '35'}
                    />
                    <StepCounter
                        page_title = {'Stairs After Landing'}
                        handleChangeObject={this.handleChangeObject}
                        handleChangeEstimateReason={this.handleChangeEstimateReason}
                        showpage = {true}
                        nextPage={this.nextPage}
                        default_next = {'Recieved Response'}
                        pages={pages}
                        header_question = {'How many stairs are there AFTER the turn (or landing)?'}
                        state_var = {'number_stairs_batch'}
                        values_customer={values_customer} 
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                        inpersons={this.state.inpersons}
                    />
                    <ContinueFooter
                        key={current_page}
                        nextPage={(next) => this.handleObstacleChoice('number_stairs_batch', this.state.number_stairs_batch, next)}
                        nextpage = {'Recieved Response'}
                        showpage = {true}
                        enable = {true}
                    />
                </div>
            )
        }
        else if (current_page === "Recieved Response"){
            returnedPage =  (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}
                        nav_type = 'none'
                        is_mobile_display = {is_mobile_display}
                    />
                    <MakeStatement
                        page_title = {'Recieved Response'}
                        handleChangeNext={this.handleChangeNext}
                        showpage = {true}
                        nextPage={this.nextPage}
                        next = {'Obstacles'}
                        pages={pages}
                        duration = {1500}
                        //img_src = {(businessid==='croccoatings') ? 'https://fq-concrete.s3.us-east-2.amazonaws.com/CrocCoatings/warranty-icon.png' : null }
                        header_statement = {<div className='row middle nowrap'><FontAwesomeIcon icon={faPlus} className='green center xx-large' /><p>&nbsp;</p><FontAwesomeIcon icon={faCheck} className='green center' /></div>}
                        header_class_size = {'largest'}
                        animation_effect ={'grow-fade'}
                        values_customer={values_customer}
                        values_business={values_business}  
                    />
                </div>
            )
        }
        else if (current_page === "Starting Job Address"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage}
                        values_general={values_general}
                        values_business={values_business}  
                        nav_type = 'none'
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '40'
                    />
                    <Places
                        handleChangeNext = {this.handleChangeNext}
                        handleChangeObject = {this.handleChangeObject}
                        handleUpdateDistance = {(filler_param, distance_value, new_address) => this.handleUpdateDistance('address_start', distance_value, new_address)}
                        nextPage={this.nextPage}
                        checkInpersons={this.checkInpersons}
                        next = {'Destination Job Address'}
                        showpage = {true}
                        values_customer={values_customer}   
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}    
                        pages = {pages}     
                        custom_header = {'What is the CURRENT address of the piano?'}
                        destination_state_var = 'address_start'
                        source_state_val = {values_customer.branch.hq_coord}//source_state_val = {values_customer.branch.address}
                        distance_mode = {travel_distance_mode}
                    />
                </div>
            )
        }
        else if (current_page === "Destination Job Address"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage}
                        values_general={values_general}
                        values_business={values_business}  
                        nav_type = 'none'
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '50'
                    />
                    <Places
                        handleChangeNext = {this.handleChangeNext}
                        handleChangeObject = {this.handleChangeObject}
                        handleUpdateDistance = {(filler_param, distance_value, new_address) => this.handleUpdateDistance('address_end', distance_value, new_address)}
                        nextPage={this.nextPage}
                        next = {'Travel Distance Estimate'}
                        showpage = {true}
                        values_customer={values_customer}   
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}    
                        pages = {pages}     
                        custom_header = {'What will be the NEW address of the piano?'}
                        destination_state_var = 'address_end'
                        source_state_val = {values_customer['address_start']}
                        distance_mode = {travel_distance_mode}
                    />
                </div>
            )
        }
        else if (current_page === "Travel Distance Estimate"){ //if the travel distance is greater than the maximum quotable distance, the user will be prompted to call for a quote
            const {travel_distance} = this.state;
            console.log("rendering travel distance estimate");
            console.log(this.state.travel_distance_total);
            console.log(this.state.travel_distance_max_quotable);
            console.log("Travel distance: ", travel_distance)
            if(travel_distance.stageA === undefined || travel_distance.stageB === undefined || travel_distance.stageC === undefined || travel_distance.stageA === null || travel_distance.stageB === null || travel_distance.stageC === null){ //wait till all stages are calculated before skipping
                returnedPage = (
                    <div className={container_class} key={this.state.travel_distance}>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <FontAwesomeIcon 
                            icon={faSyncAlt}
                            class='fa-spin spinner'
                        />
                    </div>
                )
            } else if ( ((travel_distance.stageA) > this.state.travel_distance_max_quotable && (travel_distance.stageC) > this.state.travel_distance_max_quotable) || travel_distance.stageA === 'Unable to calculate distance.' || travel_distance.stageB === 'Unable to calculate distance.' || travel_distance.stageC === 'Unable to calculate distance.'){ //neither the starting nor ending address are within the quotable distance 
                returnedPage = (
                    <div className={container_class}>
                        <Header
                                prevPage={this.prevPage} 
                                values_general={values_general}
                                values_business={values_business}
                                is_mobile_display = {is_mobile_display}
                            />
                        <ProgressBar
                            progress= '100'
                        />
                        <MakeStaticStatement
                            page_title = {`Outside Servicable Area (${address_start} & ${address_end})`}
                            handleChangeNext={this.handleChangeNext}
                            showpage = {true}
                            nextPage={this.nextPage}
                            next = {'Schedule'} //does not proceed to next page
                            pages={pages}
                            header_statement = {`Sorry! Looks like your move is outside of our service area (${branch.name} and surrounding).`}
                            custom_component = {
                            <div className='column'>
                                {schedule_modal}
                                <p className='m-small'>Fortunately, there's no need to start over. We do have a highly recommended partner mover in your area. Would you like us to pass them your job information to finish your quote for you?</p>
                                <button className='submitleadbtn' onClick={() => this.handleShowModal('Partner Quote Request')}>Yes, please</button>
                            <p className='medium invis'>.</p>
                            <p className='center x-small'>If you feel you got this message in error, please call us at <a href type='tel' src={branch.phone}>{branch.phone}</a></p>
                            </div>
                        }
                            values_customer={values_customer}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                    </div>
                )
            }
            else {
                returnedPage = (
                    <div className={container_class}>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '100'
                        />
                        <ScheduleContact
                            //key = {current_page}
                            pagetype={'call'} 
                            handleScheduleEstimate={this.handleScheduleEstimate}
                            handleChangeObject={this.handleChangeObject} 
                            nextPage={this.nextPage}
                            default_nextpage = {'Instructions (Destination)'}
                            showpage = { (travel_distance.stageA + travel_distance.stageB + travel_distance.stageC) > this.state.travel_distance_max_quotable}
                            values_customer={values_customer}
                            values_business={values_business}
                            win_width = {win_width}        
                            is_mobile_display = {is_mobile_display}      
                            email_regex = {this.state.EMAIL_REGEX}
                            phone_regex = {this.state.PHONE_REGEX}     
                        />
                    </div>
                )
            }
        }
        else if (current_page === "Review Selections"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage}
                        restartSurvey={this.restartSurvey}
                        values_general={values_general}
                        values_business={values_business}  
                        nav_type = 'restart'
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '88'
                    />
                    <ReviewSelections
                        handleChangeObject={this.handleChangeObject}
                        handleEditInput={this.editSequentialInput}
                        handleDeleteInput={this.removeFromSequentialInput}
                        nextPage={this.nextPage}
                        //next = {'Ma'}
                        showpage = {true}
                        pages={pages}
                        values_customer={values_customer} 
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                        sequence_items = {this.state.sequential_input}
                        signalReviewComplete = {this.handleReviewedSelections}
                        reviewed_selections = {this.state.reviewed_selections}
                    />
                    <ContinueFooter
                        key={current_page}
                        nextPage={this.nextPage}
                        nextpage = {'Urgency'}
                        showpage = {true}
                        enable = {true}
                    />
                </div>
            )
        }
        else if (current_page === "Urgency"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}   
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '10'
                    />
                    <MultipleChoiceText
                        page_title = {current_page}
                        key={current_page}
                        handleChangeObjectNext={this.handleChangeObjectNext}
                        showpage = {values_business.businessid !== 'safeandsoundpianos'}
                        nextPage={this.nextPage}
                        default_next = {'Other Info'}
                        pages={pages}
                        header_question = {`How soon do you need your ${moving_object_name} moved?`}
                        default_state_var = {'urgency'}
                        choices = {
                            [
                                {title: 'Urgent', description: '(within the next 24 hours)', value: 'high (next 24 hours)', nextpage: 'Schedule'},
                                {title: 'Not urgent', value: 'low'},
                            ]
                        }
                        values_customer={values_customer} 
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                    />
                </div>
            )
        }
        else if (current_page === "Other Info"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage}
                        values_general={values_general}
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '90'
                    />
                    <FreeFormTextInput
                        page_title = {current_page}
                        handleChangeObject={this.handleChangeObject}
                        nextPage={this.nextPage}
                        next = {'Email Statement'}
                        showpage = {true}
                        pages={pages}
                        header_question = {`Is there anything else you'd like to tell us about this job?`}
                        header_statement = {`i.e. Gate codes, apartment complex access, booby traps, etc. (Optional)`}
                        state_var = {'other_info'}
                        values_customer={values_customer}
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                    />
                    <ContinueFooter
                        key={current_page}
                        nextPage={this.nextPage}
                        nextpage = {'Email Statement'}
                        showpage = {true}
                        enable = {true}
                    />
                </div>
            )
        }       
        else if (current_page === "Email Statement"){
            returnedPage = (
                <div className={container_class}>
                    <MakeStatement
                        page_title = {'Email Statement'}
                        handleChangeNext={this.handleChangeNext}
                        showpage = {!values_customer.email}
                        nextPage={this.nextPage}
                        next = {'Email Address'}
                        pages={pages}
                        header_statement = {`Your quote is ready! We just need some information first to prove you're not a robot...`}
                        values_customer={values_customer}
                        values_business={values_business}  
                    />
                </div>
            )  
        }
        else if (current_page === "Email Address"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage}
                        values_general={values_general}
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '96'
                    />
                    <CollectContact
                        handleChange={this.handleChange}
                        nextPage={this.nextPage}
                        next = {'Quote'}
                        showpage = {true}
                        page_title = {current_page}
                        pages={pages}
                        values_customer={values_customer} 
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                        getDBQuote={this.getDBQuote}
                        contact_placeholder = 'Email'
                        regex = {this.state.EMAIL_REGEX}   
                        enable = {true}                                                 
                    />
                </div>
            )
        }
        else if (current_page === "Quote"){
            returnedPage = (
                <div className={container_class}>
                    <Header
                        prevPage={this.prevPage}
                        values_general={values_general}
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '100'
                    />
                    <QuotePackages_v2
                        handleChange={this.handleChange}
                        handleChangeObject={this.handleChangeObject}
                        handleSaveQuote={this.handleSaveQuote}
                        handleScheduleJob={this.handleScheduleJob}
                        handleViewedQuote={this.handleViewedQuote}
                        values_customer={values_customer}
                        values_business={values_business}
                        values_quote={values_quote} 
                        addon_items={values_business.addon_items}
                        singlepackage={true}  
                        is_mobile_display = {is_mobile_display}    
                        email_regex = {this.state.EMAIL_REGEX}
                        phone_regex = {this.state.PHONE_REGEX}
                    />
                </div>
            )
        }
        else{
            returnedPage = (
                <div className={container_class} key={branch_options}>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}   
                        is_mobile_display = {is_mobile_display}
                    />
                    <FontAwesomeIcon 
                        icon={faSyncAlt}
                        class='fa-spin spinner'
                    />
                </div>
            )

        }
        return (
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} onLoad={this.setMapLoaded} libraries={this.props.google_maps_libraries}>
                {this.state.map_loaded ? //wait for Error Boundary to render until map is loaded
                    <ErrorBoundary
                        key={pages}
                        handleScheduleEstimate={this.handleScheduleEstimate}
                        handleChangeObject={this.handleChangeObject} 
                        prevPage={this.prevPage}
                        values_customer={values_customer}
                        values_business={values_business}  
                        values_general={values_general}
                        is_mobile_display = {is_mobile_display}      
                        email_regex = {this.state.EMAIL_REGEX}
                        phone_regex = {this.state.PHONE_REGEX}  
                    >   
                        {returnedPage}
                    </ErrorBoundary> 
                    :{returnedPage}
                }
            </LoadScript>
        )     
    }
}

export default PianoSurvey;
